import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
function OurVision() {
  return (
    <div>
        <Navbar/>
        OurVision
        <Footer/>
        </div>
  )
}

export default OurVision