import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
function Footer() {
    return (
        <div>
            <footer class="footer">
                <div class="container">
                    <div class="row">
                        <div class="footer-col">
                            <h4><Link to="/who-we-are">About Us</Link></h4>
                            <h4><Link to="/terms-and-conditions">Terms and Conditions</Link></h4>
                        </div>
                        <div class="footer-col">
                            <h4><Link to="/who-we-are">Vision and Mission</Link></h4>
                            <h4><Link to="/privacy-policy">Privacy Policy</Link></h4>
                        </div>
                        <div class="footer-col">
                            <h4><Link to="/who-we-are">Our Team</Link></h4>
                            <h4><Link to="/Schedule-of-charges">Schedule Of Charges</Link></h4>
                        </div>
                        <div class="footer-col">
                            <h4><Link to="/contact-us">Contact us</Link></h4>
                            <h4><Link to="/gallery">Gallery</Link></h4>
                        </div>
                    </div>
                </div>
                <div class="footer-rights">
                    <p>2024@Kaabil Finance, All Rights Reserved</p>
                </div>
            </footer>
        </div>
    )
}
export default Footer