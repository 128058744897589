import React, { useEffect, useState } from 'react'; 
import './contact_us.css';
import Navbar from '../nav/Navbar';
import Footer from '../footer/Footer';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [params, setParams] = useState({
    fullName: '',
    email: '',
    mobileNumber: '',
    inquiryType: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const bodyCode = () => {
    return `
      <html>
      <body>
      <h4>Dear Team,</h4><br/>
      <p>A new inquiry has been initiated by a user from the Kaabil Finance website.</p>
      <p>User details are mentioned below:</p>
      <p><b>Name:</b> ${params.fullName}</p>
      <p><b>Email:</b> ${params.email}</p>
      <p><b>Mobile:</b> ${params.mobileNumber}</p>
      <p><b>Inquiry Type:</b> ${params.inquiryType}</p>
      <p><b>Message:</b> ${params.message}</p>
      </body>
      </html>
    `;
  };
console.log(params.fullName,"params.fullNameparams.fullNameparams.fullName")
  const emailInitiate = async (e) => {
    e.preventDefault(); // Prevent the form from reloading the page

    let allData = {
      to_address_list: ["care@kaabilfinance.com"],
      cc_address_list: ['ayush.gupta@kaabilfinance.org'],
      subject: `${params.inquiryType} Inquiry: ${params.fullName}`,
      s3_url: "",
      body: bodyCode(),
    };

    const requestUrl = `https://kfanalytics.kaabilfinance.com/emailsender`;

    try {
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(allData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      alert("Data Save successfully", data);

 setParams({
      fullName: '',
      email: '',
      mobileNumber: '',
      inquiryType: '',
      message: ''
    });
    } catch (err) {
      console.error("Error in sending email", err);
      alert("Error in sending email");
    }
  };

  return (
    <div>
      <Navbar />
      <div className='news_media'>
        <h1>Contact Us</h1>
      </div>
      <div className='main_contact'>
        <h1 id="colorRed">Kaabil Finance Private Limited</h1>
        <section className="main_contact_columns">
          <div className="main_contact_column">
            <h2 id="colorBlue">Registered Office Address</h2>
            <p>328 C, Kusum Vihar, Ramnagaria Road, Jagatpura, Jaipur, Rajasthan 302025</p>
            <h3><i className="fa fa-phone" style={{ color: "#013B81", fontSize: "20px" }}></i> 0141-4005012</h3>
            <h3><i className="fa fa-envelope" style={{ color: "#013B81", fontSize: "20px" }}></i> care@kaabilfinance.com</h3>
          </div>
          <div className="main_contact_column">
            <h2 id="colorBlue">Corporate Office Address</h2>
            <p>404, Fourth Floor, Milestone Building, Gandhi Nagar, Jaipur, Rajasthan 302015</p>
            <h3><i className="fa fa-phone" style={{ color: "#013B81", fontSize: "20px" }}></i> 0141-4005012</h3>
            <h3><i className="fa fa-envelope" style={{ color: "#013B81", fontSize: "20px" }}></i> care@kaabilfinance.com</h3>
          </div>
        </section>
      </div>
      <div className='contact_body'>
        <section className='contact_section'>
          <div className="contact_section-header">
            <div className="contact_container">
              <h2>Contact Us</h2>
            </div>
          </div>

          <div className="contact_container">
            <div className="contact_row">
              <div className="contact-form">
                <form id="contact-form" onSubmit={emailInitiate}>
                  <h2>Send Query</h2>
                  <div className="input-box">
                    <input 
                      type="text" 
                      required 
                      name="fullName" 
                      placeholder='Full Name'
                      value={params.fullName}
                      onChange={handleChange}
                    />
                  
                  </div>

                  <div className="input-box">
                    <input 
                      type="email" 
                      required 
                      name="email" 
                      placeholder='Email'
                      value={params.email}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-box">
                    <input 
                      type="text" 
                      required 
                      name="mobileNumber" 
                      value={params.mobileNumber}
                      onChange={handleChange}
                      placeholder='Mobile Number'
                    />
                  </div>

                  <div className="input-box">
                    <select 
                      required 
                      name="inquiryType" 
                      value={params.inquiryType}
                      onChange={handleChange}
                    >
                      <option value="">Select Inquiry Type</option>
                      <option value="Loan">Loan</option>
                      <option value="Career">Career</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div className="input-box">
                    <textarea 
                      required 
                      name="message" 
                       placeholder='Type your Message...'
                      value={params.message}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="input-box">
                    <input type="submit" value="Send" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
