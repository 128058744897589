import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
function OurValues() {
  return (
    <div>
        <Navbar/>
        OurValues
        <Footer/></div>
  )
}

export default OurValues