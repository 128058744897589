import React, { useEffect } from 'react'
import Navbar from '../nav/Navbar'
import Footer from '../footer/Footer'
import './about.css'
import qq from '../img/qq.jpg'
import logo1 from '../img/Screenshot (1).png'
import CountUp from 'react-countup';
import Ramavtar from '../img/Ramavtar.png'
import Harshal from '../img/Harshal.png'
import Anuj from '../img/Anuj.png'
import Vinod from '../img/Vinod.png'
import Vishal from '../img/Vishal.png'
import Ambit_Finvest from '../img/Ambit_Finvest.png'
import AU_Bank from '../img/AU_Bank.png'
import cholamandalam from '../img/cholamandalam.png'
import DCB_BANK from '../img/DCB_BANK.jpg'
import greenfin from '../img/greenfin.jpg'
import hinduja from '../img/hinduja.png'
import icici from '../img/icici.png'
import ikf from '../img/ikf.png'
import incred from '../img/incred.png'
import MAS from '../img/MAS.png'
import Northern_Arc from '../img/Northern_Arc.png'
import SBI from '../img/SBI.png'
import Shrira from '../img/Shrira.jpg'
import CIFL from '../img/CIFL.png'
import ugro from '../img/ugro.png'
import vistaar from '../img/vistaar.png'
function WhoWeAre() {
  // useEffect(() => {
  //   // Get the dynamic ID from the URL
  //   const url = window.location.href;
  //   const dynamicId = url.split('#')[1]; // Extract the part after #

  //   // Ensure dynamicId exists and is not empty
  //   if (dynamicId) {
  //     // Scroll to the section with the dynamic ID when the component mounts
  //     const element = document.getElementById(dynamicId);
  //     if (element) {
  //       element.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // }, []);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove the '#' character
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <div>  <Navbar />
      <div className='news_media' >
        <h1>Who We Are</h1>
      </div>
      <div className='about_us'>
        <section style={{ marginTop: "3rem" }}>
          <h1 id="colorRed">About Kaabil Finance</h1>
          <p style={{ textAlign: "justify" }}>Incorporated in 2017, <span style={{ color: "#002E6E", fontWeight: "bold", textAlign: "justify" }}>Kaabil Finance Private Limited</span> <i>(‘KFPL’ or ‘Kaabil’)</i> stands as a beacon of financial support for MSME across India's Tier 3/4/5+ towns. As a dedicated non deposit taking <i>Non-Banking Financial Company (NBFC),</i> we specialize in providing secured business loans, particularly focusing on <i>Loan Against Property (LAP),</i> tailored to meet the unique needs of small shopkeepers, vendors, and nano-entrepreneurs in rural India.</p>
        </section>
        <section style={{ marginTop: "4rem" }}>
          <div class="about_container">
            <div class="text">
              <h1 id="colorRed">Our Journey</h1>
              <p style={{ textAlign: "justify" }}>Since our inception, our journey has been marked by significant milestones and unwavering commitment. We take immense pride in our role in fostering the growth of MSME of Bharat. Our experience has confirmed our belief in this vital segment of the economy. The resilience and responsible use of finance by our clients, even during challenging times, reaffirms that we are on the right path.</p>
            </div>
            <div class="image">
              <img src={qq} alt="YourImage" />
            </div>
          </div>

          <div class="about_container" id='OurVision'>
            <div class="text" >
              <h1 id="colorRed">Our Mission</h1>
              <p style={{ textAlign: "justify" }} id='OurValues'>At Kaabil, we believe that the path to a prosperous and capable India lies in the inclusion and empowerment of rural Micro, Small, and Medium Enterprises (MSMEs). Our mission is to be the driving force behind this transformation, offering the necessary financial support that helps these businesses grow and thrive.</p>
              <h1 id="colorRed">Our Vision</h1>
              <p style={{ textAlign: "justify" }}>We are out to prove that rural small businesses are as ‘Kaabil’ or ‘capable’ of accessing formal credit as any other borrower. Our work does not stop at finding the right customer. We also work to make our customers ‘Kaabil’, helping improve their credit behaviour and profile. Our task is unfinished until every small rural business gains access to credit and sound financial advice.</p>
              <h1 id="colorRed">Our Values</h1>
              <p style={{ textAlign: "justify" }}><span style={{ color: "#002E6E", fontWeight: "bold", textAlign: "justify" }}>Expeditiousness: </span> We value the importance of acting swiftly and efficiently to meet client needs and adapt to changing conditions.</p>
              <p style={{ textAlign: "justify" }}><span style={{ color: "#002E6E", fontWeight: "bold", textAlign: "justify" }}>Integrity: </span> We are committed to the highest standards of honesty and transparency in all our interactions.</p>
              <p style={{ textAlign: "justify" }}><span style={{ color: "#002E6E", fontWeight: "bold", textAlign: "justify" }}>Empathy: </span> We recognize and respect the unique challenges faced by small businesses in rural areas.</p>
              <p style={{ textAlign: "justify" }}><span style={{ color: "#002E6E", fontWeight: "bold", textAlign: "justify" }}>Innovation: </span> We encourage a culture of creativity and continuous improvement among our employees, promoting an environment where new ideas are welcomed and explored.</p>
              <p style={{ textAlign: "justify" }}><span style={{ color: "#002E6E", fontWeight: "bold", textAlign: "justify" }}>Sustainability: </span> We are committed to promoting sustainable practices in all aspects of our operations.</p>

            </div>

          </div>
        </section>

        <section style={{ marginTop: "4rem" }}>
          <div class="about_container">
            <div class="image">
              <img src={logo1} alt="Your Image" />
            </div>
            <div class="text" id="rrr">
              <h1 id="colorRed">Geography</h1>
              <p>Kaabil Finance offers Secured Business Loans Against Property, providing convenient financial solutions with competitive rates and flexible terms to meet diverse borrowing needs efficiently.</p>
              <table className="table-view" width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr aria-rowindex="1">
                    <td data-celllook="256"><b><span data-contrast="none">Branches</span></b><span data-ccp-props="{}">&nbsp;</span></td>
                    <td data-celllook="256"><span data-ccp-props="{}">&nbsp;</span></td>
                    <td style={{ textAlign: 'center' }} data-celllook="256"><b><span data-contrast="none">January-2024</span></b><span data-ccp-props="{}">&nbsp;</span></td>
                  </tr>
                  <tr aria-rowindex="2">
                    <td data-celllook="0" style={{ fontSize: "20px", fontWeight: "bold" }} id="colorBlue"> Rajasthan</td>
                    <td data-celllook="0"></td>
                    <td style={{ textAlign: 'center', fontSize: "20px", fontWeight: "bold" }} id="colorBlue" data-celllook="0"><span data-contrast="none"> <CountUp end={68} duration={5} />+</span></td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>

        </section>



        {/* <div class="blog-slider">
  <div class="blog-slider__wrp swiper-wrapper">
    <div class="blog-slider__item swiper-slide">
      <div class="blog-slider__img">
        
        <img src="https://res.cloudinary.com/muhammederdem/image/upload/q_60/v1535759872/kuldar-kalvik-799168-unsplash.webp" alt=""/>
      </div>
      <div class="blog-slider__content">
        <span class="blog-slider__code">26 December 2019</span>
        <div class="blog-slider__title">Origination</div>
        <div class="blog-slider__text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Recusandae voluptate repellendus magni illo ea animi? </div>
        <div class="blog-slider__text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Recusandae voluptate repellendus magni illo ea animi? </div>
      </div>
    </div>
  </div>
</div> */}


        <h2 style={{ textAlign: "center", fontSize: "2em", marginTop: "3rem" }} id="colorRed"><b>Our Debt Lenders Partners</b></h2>
        {/* <div class="about_slider" id="oooo">
	<div class="about_slide-track">
		<div class="about_slide">
			<img src={p1}  alt="" />
		</div>
		<div class="about_slide" style={{paddingLeft:"2rem"}}>
			<img src={p2}  alt="" />
		</div>
		
	</div>
</div> */}


        <section className='our-debt-lender' id="OurPartners">
          {/* <h1>Our Partners</h1> */}
          <div class="our-debt-lender-slider">
            <div class="our-debt-lender-slider-items">
              <img src={Ambit_Finvest} alt="" />
              <img src={AU_Bank} alt="" />
              <img src={cholamandalam} alt="" />
              <img src={DCB_BANK} alt="" />
              <img src={greenfin} alt="" />
              <img src={hinduja} alt="" />
              <img src={icici} alt="" />
              <img src={ikf} alt="" />
              <img src={incred} alt="" />
              <img src={MAS} alt="" />
              <img src={Northern_Arc} alt="" />
              <img src={SBI} alt="" />
              <img src={Shrira} alt="" />
            </div>
          </div>

        </section>


        <h2 style={{ textAlign: "center", fontSize: "2em", marginTop: "3rem" }} id="colorRed"><b>Our Co-Lending Partners</b></h2>

        <div class="partner-logos">
          <div class="partner-logo">
            <img src={CIFL} alt="CIFL" />
          </div>
          <div class="partner-logo">
            <img src={ugro} alt="ugro" />
          </div>
          <div class="partner-logo">
            <img src={vistaar} alt="vistaar" />
          </div>
        </div>



      </div>
     
      <section  style={{ marginTop: "3rem" }}>
        <h2 style={{ textAlign: "center", fontSize: "2em", marginTop: "3rem" }} id="colorRed">Meet Our Team   
        </h2>
        <div class="Team_container">
          <div class="Team_card">
            <div class="Team_content">
              <div class="Team_front"><img src={Ramavtar} /><p style={{ fontWeight: "bold", fontSize: "18px" }} id="colorBlue">Ramavtar Meena <br />CEO</p></div>
              <div class="Team_back">Kaabil
                Finance is the brainchild of Ramavtar Meena the Founder, Managing
                Director and CEO of the company. He
                is an alumnus of IIM Lucknow with 7+ years of experience in Banking
                Industry. He has worked as Relationship Manager with ICICI Bank,
                corporate Banking division (BKC, Mumbai). He conceptualized and
                founded Kaabil Finance in 2017.</div>
            </div>
          </div>
          <div class="Team_card">
            <div class="Team_content">
              <div class="Team_front"><img src={Harshal} /><p style={{  fontWeight: "bold", fontSize: "18px" }} id="colorBlue">Harshal Chandak<br />CFO</p></div>
              <div class="Team_back">Alumnus of IIM
Lucknow with 16 + years across education, banking & logistics
sectors. He worked with ICICI Bank, IndusInd Bank, Delhivery and
Mahindra Logistics, among others. As a Senior Director at Delhivery
he grew the portfolio more than 20x in 5 years.</div>
            </div>
          </div>
          <div class="Team_card">
            <div class="Team_content">
              <div class="Team_front"><img src={Anuj} /><p style={{fontWeight: "bold", fontSize: "18px" }} id="colorBlue">Anuj Gupta<br />Credit Head</p></div>
              <div class="Team_back"> MBA
Finance professional with over 18 years of expertise in credit risk
management, financial analysis, project and corporate finance.
Demonstrates strong analytical acumen, interpersonal skills, and
adeptness in team management. Proactive and inquisitive, prioritizing
clear communication and fostering independent opinions based on sound
judgment and intuition.</div>
            </div>
          </div>
      
          <div class="Team_card">
            <div class="Team_content">
              <div class="Team_front"><img src={Vishal} /><p style={{fontWeight: "bold", fontSize: "18px" }} id="colorBlue">Vishal Jain

                <br />Technology Head</p></div>
              <div class="Team_back"><p>Vishal, a B.Tech graduate from NIT Kurukshetra, began his career as a Software Engineer at HP, later advancing to Senior Software Engineer roles at Sterling Commerce and eMeter Corporation. With extensive industry experience, he founded Lazy Yatra, where he served as Founder for 9 years.

</p></div>
            </div>
          </div>
        </div>

      </section>
      <Footer />
    </div>
  )
}

export default WhoWeAre