import React, { useState, useEffect, useRef } from 'react';
import './home.css'; // Import a separate CSS file for styling
import CountUp from 'react-countup';
// import Navrbar from '../navbar/Navbar'
import Nav from '../nav/Navs';
import Footer from '../footer/Footer'
import SBL from '../img/SBL.png'
import gold from '../img/gold.png'
import cust1 from '../img/cust1.jpg'
import cust2 from '../img/cust2.jpeg'
import cust3 from '../img/cust3.png'
import cust4 from '../img/cust4.jpeg'
import cust5 from '../img/cust5.jpeg'
import cust6 from '../img/cust6.jpg'
import who_we_are from '../img/who_we_are.jpeg'
const Slider = ({ images }) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(nextIndex);
    }, 5000);

    return () => clearInterval(intervalId);

  }, [currentIndex, images.length]);

  return (
    <div className="slider">
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
    </div>
  );
};

const Home = () => {

  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      heading: 'सिद्धि सपनों की, समृद्धि अपनों की',
      content: `I am Tarachand, and I want to say that I took a loan of 6 lakh rupees from Kaabil for my business. With this loan, I opened two shops - a grocery store and a sweet shop.
      Impressed by Kaabil's excellent service, I took another loan of 9.5 lakh rupees.
      With the help of these loans, my business has doubled. Kaabil's outstanding service has played a crucial role in expanding my business.`,
      name: "Tarachand",
      image: cust1,

    }, {
      heading: 'सिद्धि सपनों की, समृद्धि अपनों की',
      content: `I, Jagdish Kumar, am writing to share my positive experience with Kaabil. About 8-9 months ago, I took a loan of 3 lakh rupees from Kaabil and opened a sweet shop.
      Previously, I was doing the sweet business from home, but with Kaabil's support, I started this new venture. As a result, I now earn around 11-12,000 rupees daily.
      I am grateful for Kaabil's service and assistance. Their staff helped me succeed in my business and guided me towards fulfilling my dreams.`,

      name: "Jagdish Kumar",
      image: cust2, // Replace with your image URL
    }, {
      heading: 'सिद्धि सपनों की, समृद्धि अपनों की',
      content: `I, Uma Shankar Prajapat, would like to commend Kaabil's services through this letter. By taking a loan of approximately 12 lakh rupees, I expanded my welding shop.
      With the help of this loan, I purchased a new shutter-making machine, which has resulted in my income doubling.
      I am pleased that Kaabil helped in growing my business and supported me in fulfilling my dreams.`,
      name: "Uma Shankar Prajapat",
      image: cust3, // Replace with your image URL
    },
    {
      heading: 'सिद्धि सपनों की, समृद्धि अपनों की',
      content: `I, Inder Singh, would like to commend Kabil's services through this letter. About 3 months ago, I took a loan of 5 lakh rupees for my JCB contracting business.
      Due to Kabil's fast process and smooth documentation, I received the loan in just 15-20 days. Because of this speed and ease of documentation, I chose Kabil.
      This tractor is helping me earn more income.`,
      name: "Inder Singh",
      image: cust4, // Replace with your image URL
    },
    {
      heading: 'सिद्धि सपनों की, समृद्धि अपनों की',
      content: `हम, शिवराम मेघवाल और डाली देवी मेघवाल, काबिल की सेवाओं के प्रति अपनी प्रशंसा व्यक्त करना चाहते हैं। लगभग 5 महीने पहले, हमने अपने रेस्टोरेंट के विस्तार के लिए 3 लाख रुपये का ऋण लिया था।
इस ऋण की सहायता से हमने अपने रेस्टोरेंट का विस्तार करके एक गेस्ट हाउस खोला। पहले हमारी दैनिक आय 5-6 हजार रुपये थी, लेकिन अब हमारी आय 8-9 हजार रुपये प्रति दिन हो गई है।
काबिल के साथ हमारा अनुभव बहुत अच्छा रहा है और हम भविष्य में और अधिक ऋण लेकर अपने व्यवसाय का विस्तार करके एक गार्डन का कार्य शुरू करना चाहते हैं।`,
      name: " Shivram Meghwal and Dali Devi Meghwal",
      image: cust5, // Replace with your image URL
    },
    {
      heading: 'सिद्धि सपनों की, समृद्धि अपनों की',
      content: `
        मैं केशर बाई आपको खुशी से सूचित करना चाहती हूँ कि मैंने 'काबिल फाइनेंस' से 3-4 महीने पहले एक ऋण लिया था। मुझे 2 लाख रुपये का ऋण मिला था जिसका मुख्य उद्देश्य मेरी किराने की दुकान की शुरुआत करना था। पहले मेरी महीने की कमाई लगभग 4000 रुपये थी, लेकिन इस ऋण की सहायता से अब मेरी महीने की कमाई 7000 रुपये हो गई है।
        मैं 'काबिल फाइनेंस' की टीम का ह्रदय से आभारी हूँ जिन्होंने मुझे मेरे व्यापार के लिए इस प्रकार का समर्थन दिया। उनकी सेवाओं के प्रति मेरा विशेष आभार आदर रहेगा।
      `,
      name: "Keshar Bai",
      image: cust6, // Replace with your image URL
    }

  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide, slides.length]);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  return (
    <>
      <div>
        <Nav />

        <div class="with-bg-size">

          <div id="color-overlay">

          </div>
        </div>
        <div class="home_card">

          <h1>Welcome To</h1>
          <h1>Kaabil Finance Pvt Ltd</h1>
        </div>
      </div>
      <h1 className='heading' style={{ marginTop: "3rem", color: "#EC1C24" }}>WHO WE ARE</h1>
      <div class="contant">
        <div class="left-side">
          <img src={who_we_are} alt="Left Side Image" />
        </div>
        <div class="right-side">

          <p>
            <div style={{ textAlign: "justify" }}>

              Incorporated in 2017, <span style={{ color: "#002E6E", fontWeight: "bold" }}>Kaabil Finance Private Limited </span>  <i>(‘KFPL’ or ‘Kaabil’)</i> stands as a beacon of financial support for MSME across India's Tier 3/4/5+ towns. As a dedicated non deposit taking Non-Banking Financial Company (NBFC), we specialize in providing secured business loans, particularly focusing on Loan Against Property (LAP), tailored to meet the unique needs of small shopkeepers, vendors, and nano-entrepreneurs in rural India.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; At Kaabil, we firmly believe that advancing towards a prosperous and capable India requires the active inclusion of rural MSMEs. Our relatively short but impactful journey has reaffirmed our deep conviction in this segment. The rural MSMEs have consistently demonstrated a remarkable willingness and capacity to manage finance responsibly, even amidst challenging circumstances. Their dedication and resilience confirm that we are on the right path, driving meaningful progress and fostering inclusive growth across the country.

            </div>

            <div className="customer" style={{ paddingTop: "2rem" }}>
              <div className="new_wwa_count_items">
                <h3 title="5000+">
                  <span className="text-huge">
                    <strong style={{ fontSize: "20px" }} id="colorBlue">
                      <CountUp end={5000} duration={5} />+
                    </strong>
                  </span>
                </h3>
                <p>Customers</p>
              </div>
              <div className="new_wwa_count_items">
                <h3 title="150+ Cr&nbsp;">
                  <span className="text-huge">
                    <strong style={{ fontSize: "20px" }} id="colorBlue">
                      <CountUp end={150} duration={5} />+ Cr&nbsp;
                    </strong>
                  </span>
                </h3>
                <p>AUM</p>
              </div>
              <div className="new_wwa_count_items">
                <h3 title="68+&nbsp;&nbsp;">
                  <span className="text-huge">
                    <strong style={{ fontSize: "20px" }} id="colorBlue">
                      <CountUp end={68} duration={5} />+&nbsp;&nbsp;
                    </strong>
                  </span>
                </h3>
                <p>Branches</p>
              </div>
              <div className="new_wwa_count_items">
                <h3 title="540+&nbsp;">
                  <span className="text-huge">
                    <strong style={{ fontSize: "20px" }} id="colorBlue">
                      <CountUp end={540} duration={5} />+&nbsp;
                    </strong>
                  </span>
                </h3>
                <p>Employees</p>
              </div>
            </div>
          </p>
        </div>
      </div>
      <h1 className='heading' style={{ marginTop: "1rem" }} id="colorRed"> PRODUCT WE OFFER </h1>
      <div className='main_contact'>




        <section class="main_contact_columns">
          <div class="main_contact_column ">
            <h2 style={{ textAlign: "center" }} id="colorBlue">Empower Your Business with SBL</h2>
            <p style={{ textAlign: "justify" }}>At Kaabil, we recognize the pivotal role that financing can play in accelerating your business growth. Our Secured Business Loan is designed to provide substantial funding while offering you favorable terms and conditions. By leveraging your property as collateral, you can access the capital needed to propel your business forward with greater confidence.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Our Secured Business Loan is not just about providing funds—it's about fostering an inclusive environment where small businesses can thrive. By leveraging your assets, we help you access the capital needed to grow and succeed. Our commitment is to support small businesses from diverse backgrounds, contributing to a more inclusive and dynamic economic landscape.
            </p>
          </div>
          <div class="main_contact_column" id="businesss">
            <img src={SBL} alt='business loan logo' />
          </div>
        </section>
      </div>
      <h1 className='heading' style={{ marginTop: "3rem" }} id="colorRed"> WHAT OUR CUSTOMERS SAY</h1>
      <div className="slider-container" style={{ marginTop: "1rem" }}>
        <div className="left-content">
          <h1>{slides[currentSlide].heading}</h1>
          <p style={{ textAlign: "justify" }}>
            {slides[currentSlide].content.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </p>
          <span>{slides[currentSlide].name}</span>
        </div>
        <div className="right-image" style={{ backgroundImage: `url(${slides[currentSlide].image})` }} />
        <div className="controls">
          <button onClick={handlePrevSlide}>&#8249;</button>
          <button onClick={handleNextSlide}>&#8250;</button>
        </div>
      </div>




      <Footer />
      {/* <div className="homepage">
      {showPopup && <Popup onClose={handleClosePopup} />}
      Homepage content
    </div> */}
    </>
  );
};

export default Home;
